import React, { useEffect } from "react"
import SharePlayedImage from '../images/share-played.jpg';
import Layout from "../components/layout";
import SEO from "../components/seo";

const SharePlayedPage = ({ location }) => {
  useEffect(() => {
    window.location = location.origin;
  }, []);

  return (
    <Layout showHeader={false}>
      <SEO 
        title="I just played Piing Party!" 
        shareImage={ SharePlayedImage } 
        location={ location }
      />
    </Layout>
  )
}

export default SharePlayedPage